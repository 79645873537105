<template>
  <div class="md:block hidden xl:container mx-auto font-sans lg:px-8 px-2">
    <div class="flex items-center py-5">
      <div class="mr-20 dark:text-gray-100">
        <router-link to="/">
          <img
            src="@/assets/logo_hamroh.svg"
            alt=""
            class="dark:filter dark:grayscale dark:invert"
          />
        </router-link>
      </div>
      <div>
        <ul class="flex">
          <li
            class="mr-3 cursor-pointer relative"
            @click="showModal = !showModal"
          >
            <div class="flex items-center">
              <li
                class="text-gray-500 hover:text-black text-center hover:bg-gray-100 px-4 py-2 hover:rounded-lg"
              >
                <a href="https://hamroh.com/app"> Междугородние поездки </a>
              </li>
            </div>
          </li>
          <!-- <li class="text-gray-500 hover:text-black text-center hover:bg-gray-100 px-4 py-2 hover:rounded-lg">
              <a href="/blog">Блог</a>
            </li>  -->
          <li
            class="text-gray-500 hover:text-black text-center hover:bg-gray-100 px-4 py-2 hover:rounded-lg"
          >
            <a href="/jobs">Вакансии</a>
          </li>
        </ul>
      </div>
      <div class="ml-auto">
        <a
          href="https://hamroh.com/redirect
            "
        >
          <div
            class="hover:bg-sky-400 active:bg-sky-500 rounded-2xl py-4 xl:px-12 px-4 cursor-pointer bg-[#07CAFF]"
          >
            Скачать приложение
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="md:hidden block w-full sticky z-10 top-0">
    <div class="flex items-center py-5 px-6 bg-gray-50 dark:bg-slate-900">
      <div>
        <router-link to="/">
          <img
            src="@/assets/logo_hamroh.svg"
            class="dark:filter dark:invert dark:grayscale"
            alt=""
          />
        </router-link>
      </div>
      <div class="ml-auto">
        <div class="flex items-center justify-between">
          <div @click.prevent="switchMenuBar" class="relative cursor-pointer">
            <input type="checkbox" id="burger-toggle" class="hidden" />
            <label for="burger-toggle" class="cursor-pointer">
              <svg
                class="w-10 h-10 text-gray-500 hover:text-gray-700"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div
      class="dark:bg-slate-900 bg-white h-screen fixed z-10 w-full"
      :class="openMenuBar ? 'hidden' : ''"
    >
      <ul class="px-6 dark:text-gray-100">
        <li
          class="font-bold text-lg pb-4 pt-4"
          @click="(openMenuBar = true), (mobOpenMenu = false)"
        >
          <a href="https://hamroh.com/app/passenger/trips">
            Поездки водителей
          </a>
        </li>
        <li
          class="font-bold text-lg pb-4 pt-4"
          @click="(openMenuBar = true), (mobOpenMenu = false)"
        >
          <a href="https://hamroh.com/app/performer/trips">
            Поездки пассажиров
          </a>
        </li>
        <!-- <li  @click="openMenuBar = true, mobOpenMenu= false" class="font-bold text-lg pb-4 pt-2">
            <router-link to="/service">
              Сервисы Hamroh
            </router-link>
          </li>
          <li  @click="openMenuBar = true, mobOpenMenu= false" class="font-bold text-lg py-4">
            <router-link to="/blog">
              Блог
            </router-link>
          </li> -->
        <li
          @click="(openMenuBar = true), (mobOpenMenu = false)"
          class="font-bold text-lg py-4"
        >
          <router-link to="/jobs"> Вакансии </router-link>
        </li>
      </ul>
      <div class="px-6 mx-auto">
        <a href="https://hamroh.com/app/performer/menu">
          <div
            class="border-2 border-[#07CAFF] bg-white rounded-2xl py-4 xl:px-12 px-6 cursor-pointer my-6"
          >
            <p class="text-center font-bold">Стать водителем</p>
          </div>
        </a>
        <div
          class="border-2 border-[#07CAFF] bg-[#07CAFF] rounded-2xl py-4 xl:px-12 px-6 cursor-pointer my-6"
        >
          <p class="text-center font-bold">
            <a
              href="https://play.google.com/store/apps/details?id=com.gram.hamroh"
              >Скачать приложения</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@unhead/vue";
export default {
  data() {
    return {
      openMenuBar: true,
      showModal: false,
      mobOpenMenu: false,
    };
  },
  setup() {
    useHead({
      meta: [
        {
          name: "description",
          content:
            "Найдите попутчиков для межгородних поездок. Сэкономьте на расходах и сделайте поездку удобной, зарегистрируйтесь на Hamroh.",
        },
        {
          name: "keywords",
          content:
            "попутчики, Междугородние поездки, сэкономить на поездках, найти водителя, удобные поездки",
        },
      ],
    });
  },
  methods: {
    switchMenuBar() {
      this.openMenuBar = !this.openMenuBar;
    },
  },
};
</script>
