<template>
  <div class="bg-gray-100 dark:bg-slate-900">
    <Navbar v-if="$route.name !== 'redirect'" />
    <router-view />
    <Footer v-if="$route.name !== 'redirect' && $route.name !== '404'" />
  </div>
</template>
<script setup>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { useHead } from "@unhead/vue";
import { onMounted } from "vue";
import { useRoute } from "vue-router";

const router = useRoute();

useHead({
  title: "Hamroh",
  meta: [
    {
      name: "description",
      content:
        "Найдите попутчиков для межгородних поездок на нашем сайте. Объединяйтесь с другими путешественниками, чтобы сэкономить на расходах и сделать поездку более удобной и приятной. Зарегистрируйтесь сейчас и найдите идеального попутчика для своей следующей поездки.",
    },
    {
      name: "keywords",
      content:
        "Душанбе - Худжанд, попутчики, поездки, найти пассажира, найти водителя, найти попутчика, Междугородние поездки, Худжанд - Душанбе, Душанбе, Ойбек, Худжанд,Худжанд - Ойбек, Ойбек - Худжанд, Худжанд - Панчакент, Панчакент - Худжанд,, Душанбе - Панчакент, Панчакент - Душанбе, совместные поездки",
    },
  ],
});
</script>
