<template>
  <div></div>
</template>

<script>
import api from "@/api/axios";
export default {
  data() {
    return {};
  },
  mounted() {
    this.qrscan();
    this.increaseCounter();
  },
  methods: {
    increaseCounter() {
      api
        .post(`v1/get/qr-scan?api_key=1545&slug=${this.$route.query.slug}`)
        .then((res) => {
          if (res.data.code == 200) {
            if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
              window.location.href =
                "https://play.google.com/store/apps/details?id=com.gram.hamroh";
            } else if (
              navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ||
              navigator.userAgent.toLowerCase().indexOf("ipad") > -1
            ) {
              window.location.href =
                "https://apps.apple.com/tj/app/hamroh/id6468073919";
            } else {
              window.location.href = "https://hamroh.com/app";
            }
          }
          console.log(res);
        });
    },
    qrscan() {
      api.post("v1/get/main-qr-scan?api_key=1545").then((res) => {
        console.log(res.message);
      });
    },
  },
};
</script>
