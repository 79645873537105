<template>
  <div class="bg-gray-100 dark:bg-slate-900">
    <div class="p-3">
      <Header v-if="showBanner"></Header>
    </div>
    <!-- <Service /> -->
    <!-- <Trips class="md:hidden"/> -->
    <div class="p-3">
      <LeadMagnit />
      <Statistic />
    </div>
    <div class="bg-[#07CAFF] p-6 pt-12 overflow-hidden">
      <div
        class="flex flex-col items-center md:flex-row md:justify-between justify-center max-w-[1016px] mx-auto"
      >
        <div>
          <h3 class="text-[30px] font-semibold mb-4">
            Путешествовать удобнее с приложением Hamroh
          </h3>
          <p class="text-base p-0">
            Все поездки в одном месте. Cамое удобное приложение для
            междугородних поездок
          </p>
          <div
            class="flex items-center flex-col sm:flex-row sm:justify-center md:justify-start gap-4 mt-6"
          >
            <a
              href="https://apps.apple.com/tj/app/hamroh-такси-по-межгороду/id6468073919"
              target="_blank"
            >
              <img class="h-[63px]" src="@/assets/appStore.svg" alt="" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.gram.hamroh"
              target="_blank"
            >
              <img class="h-[83px]" src="@/assets/playmarket.png" alt="" />
            </a>
          </div>
        </div>
        <img
          class="h-[500px] md:mt-0 mt-6 mb-[-200px] w-[260px] relative"
          src="@/assets/app.png"
          alt=""
        />
      </div>
    </div>
    <div class="p-3">
      <Safety />
      <Instruction />
      <FAQ />
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
// import Service from '@/components/home/Service.vue';
import Trips from "@/components/home/Trips";
import LeadMagnit from "@/components/home/LeadMagnit.vue";
import Statistic from "@/components/home/Statistic.vue";
import Safety from "@/components/home/Safety.vue";
import Instruction from "@/components/home/Instruction.vue";
import FAQ from "@/components/home/FAQ.vue";

export default {
  components: {
    Header,
    // Service,
    Trips,
    LeadMagnit,
    Statistic,
    Safety,
    Instruction,
    FAQ,
  },
  data() {
    return {
      showBanner: true,
    };
  },
  methods: {
    isMobileDevice() {
      const mobileWidth = 768;
      return window.innerWidth < mobileWidth;
    },
    checkDeviceType() {
      this.showBanner = !this.isMobileDevice();
    },
  },
  created() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDeviceType);
  },
};
</script>

<style></style>
