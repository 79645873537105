<template>
  <div class="sm:block hidden xl:container mx-auto font-sans lg:px-8 px-2 z-0">
    <div class="relative">
      <div
        class="absolute inset-0 flex items-center justify-center text-center"
      >
        <h1 class="xl:text-6xl text-5xl text-white font-bold">
          Междугородние поездки с
          <span class="text-[#07CAFF]"><br />попутчиками</span>
        </h1>
        <search class="hidden sm:block absolute top-[360px] left-0 right-0" />
      </div>
      <div
        class="bg-[url('@/assets/header_bg4.jpg')] bg-cover bg-center h-[500px] rounded-[48px]"
      ></div>
    </div>
  </div>
</template>
